var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "containerRef",
    staticClass: "container"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-bottom": "10px"
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["ambiance:btn:add"],
      expression: "['ambiance:btn:add']"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.showModal();
      }
    }
  }, [_vm._v("新建配置")])], 1), _c("a-table", {
    attrs: {
      dataSource: _vm.dataSource,
      pagination: false,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.tableLoading,
      columns: _vm.tableColumns
    },
    scopedSlots: _vm._u([{
      key: "runstatus",
      fn: function fn(runstatus) {
        return [_c("span", [_vm._v(" " + _vm._s(runstatus == 0 ? "运行中" : runstatus == 1 ? "已结束" : "已作废") + " ")])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["ambiance:btn:edit"],
            expression: "['ambiance:btn:edit']"
          }],
          staticStyle: {
            "min-width": "auto",
            padding: "0",
            margin: "0"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showModal(item);
            }
          }
        }, [_vm._v("编辑")]), _c("a-popconfirm", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["ambiance:btn:del"],
            expression: "['ambiance:btn:del']"
          }],
          attrs: {
            placement: "topLeft",
            "ok-text": "确定",
            "cancel-text": "取消"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("div", [_vm._v("温馨提示")]), _c("div", [_vm._v("是否删除")])]), _c("a-button", {
          staticStyle: {
            "min-width": "auto",
            padding: "0",
            "margin-left": "16px"
          },
          attrs: {
            type: "link"
          }
        }, [_vm._v("删除")])], 2)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "策略配置",
      width: 800,
      okButtonProps: {
        props: {
          loading: _vm.loading
        }
      },
      "get-container": function getContainer() {
        return _vm.$refs.containerRef;
      }
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "formState",
    attrs: {
      model: _vm.formState,
      labelAlign: "left",
      rules: _vm.rules,
      labelCol: {
        span: 8
      }
    }
  }, [_c("h2", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("策略配置")]), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内增粉量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.addfollowersnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "addfollowersnum", $$v);
      },
      expression: "formState.addfollowersnum"
    }
  }), _c("p", {
    class: _vm.rule.addfollowersnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内增粉量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")])], 1), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内发布视频获得额外浏览量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.addvideoimpressionsnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "addvideoimpressionsnum", $$v);
      },
      expression: "formState.addvideoimpressionsnum"
    }
  }), _vm.rule.addvideoimpressionsnumFlag ? _c("p", {
    class: _vm.rule.addvideoimpressionsnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内发布视频获得额外浏览量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内发布视频获得额外点赞量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.addvideoupvotesnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "addvideoupvotesnum", $$v);
      },
      expression: "formState.addvideoupvotesnum"
    }
  }), _vm.rule.addvideoupvotesnumFlag ? _c("p", {
    class: _vm.rule.addvideoupvotesnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内发布视频获得额外点赞量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内发布视频获得额外点踩量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.addvideodownvotesnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "addvideodownvotesnum", $$v);
      },
      expression: "formState.addvideodownvotesnum"
    }
  }), _vm.rule.addvideodownvotesnumFlag ? _c("p", {
    class: _vm.rule.addvideodownvotesnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内发布视频获得额外点踩量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内发布动态获得额外浏览量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.adddynamicimpressionsnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "adddynamicimpressionsnum", $$v);
      },
      expression: "formState.adddynamicimpressionsnum"
    }
  }), _vm.rule.adddynamicimpressionsnumFlag ? _c("p", {
    class: _vm.rule.adddynamicimpressionsnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内发布动态获得额外浏览量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    staticClass: "formModelItem",
    attrs: {
      label: "周期内发布动态获得额外点赞量"
    }
  }, [_c("a-input", {
    on: {
      blur: _vm.verify
    },
    model: {
      value: _vm.formState.adddynamicupvotesnum,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "adddynamicupvotesnum", $$v);
      },
      expression: "formState.adddynamicupvotesnum"
    }
  }), _vm.rule.adddynamicupvotesnumFlag ? _c("p", {
    class: _vm.rule.adddynamicupvotesnumFlag ? "showFlag showFlagNo" : "showFlag"
  }, [_vm._v(" 周期内发布动态获得额外点赞量不能少于周期时间" + _vm._s(_vm.dateTime) + "天 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    staticStyle: {
      "font-weight": "bold"
    },
    attrs: {
      prop: "cyclicalpatterns"
    }
  }, [_c("h2", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("策略周期")]), _c("a-radio-group", {
    model: {
      value: _vm.formState.cyclicalpatterns,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "cyclicalpatterns", $$v);
      },
      expression: "formState.cyclicalpatterns"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1,
      name: "type"
    }
  }, [_vm._v(" 每周循环 ")]), _c("a-radio", {
    attrs: {
      value: 2,
      name: "type"
    }
  }, [_vm._v(" 每月循环 ")]), _c("a-radio", {
    attrs: {
      value: 3,
      name: "type"
    }
  }, [_vm._v(" 自定义日期范围 ")])], 1), _vm.formState.cyclicalpatterns == 3 ? _c("a-range-picker", {
    staticClass: "coustom-range-picker",
    attrs: {
      format: "YYYY-MM-DD HH:mm",
      disabledDate: function disabledDate(currentDate) {
        return _vm.getNewDate() > currentDate;
      },
      placeholder: ["开始日期", "结束日期"]
    },
    on: {
      change: _vm.onDateChangeTime
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  }) : _vm._e()], 1), _c("a-form-model-item", {
    staticStyle: {
      "font-weight": "bold"
    },
    attrs: {
      prop: "uidlist"
    }
  }, [_c("h2", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("生效用户")]), _c("a-select", {
    attrs: {
      "show-search": "",
      allowClear: true,
      placeholder: "请输入UID/昵称搜索",
      "default-active-first-option": false,
      "filter-option": false,
      options: _vm.options,
      loading: _vm.selectLoading
    },
    on: {
      search: _vm.handleSearchUser,
      change: _vm.handleChange,
      popupScroll: _vm.handlePopupScroll
    },
    model: {
      value: _vm.formState.uidlist,
      callback: function callback($$v) {
        _vm.$set(_vm.formState, "uidlist", $$v);
      },
      expression: "formState.uidlist"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };