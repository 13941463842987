var searchKeys = [{
  key: "id",
  placeholder: '请输入策略ID',
  input: true,
  label: '策略ID'
}, {
  key: "username",
  placeholder: '请输入用户Username',
  input: true,
  label: '用户Username'
}, {
  key: "uid",
  placeholder: '请输入用户uid',
  input: true,
  label: '用户uid'
}, {
  key: "button",
  button: true
}];
var tableColumns = [{
  title: '策略ID',
  dataIndex: "id",
  key: "id",
  fixed: "left"
}, {
  title: '用户uid',
  dataIndex: "uidstr",
  key: "uidstr"
}, {
  title: '策略生效Username',
  dataIndex: "usernmestr",
  key: "usernmestr"
}, {
  title: '覆盖用户数',
  dataIndex: "number",
  key: "number"
}, {
  title: '策略持续类型',
  dataIndex: "cyclicalpatternsname",
  key: "cyclicalpatternsname"
}, {
  title: '状态',
  dataIndex: "runstatus",
  key: "runstatus",
  scopedSlots: {
    customRender: "runstatus"
  }
}, {
  title: '操作',
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { searchKeys, tableColumns };